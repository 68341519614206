<template>
	<AllQcmPackages :status="'PENDING'" />
</template>
<script>
import AllQcmPackages from './AllQcmPackages.vue'

export default{
	components: {
		AllQcmPackages
	}

}
</script>
